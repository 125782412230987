import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

const contentStyle = {
  padding: 150,
  borderRadius: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const SteeringCommittee = () => {
  const [loading, setLoading] = useState(true);
const handleClick = (item)=>{
  let componentToRender;

  switch (item){
    case "firstelement":
      componentToRender ="firstelement";
      break;
    case "secondelement":
      componentToRender = "firstelement";
    case "thirdelement":
      componentToRender ="thirdelement";
    case "fourelement":
      componentToRender ="fourelement";
    case "foveelement":
      componentToRender = "fiveelement";
  }
}

  return (
    <>
      <div className='center mediaTitle'>
      <h2 className='center'>Title page</h2>

        {/* <h2 className='center'>Steering Committee</h2> */}
      </div>
      
     <div className='mainContainer'>
      <div className='row'>
        <div className='col-lg-2 sideCon' >
          <ul>
            <li  onClick={(e) => handleClick("")}  className='firstelement Sidelist'></li>
            <li  onClick={(e) => handleClick("")}  className='secondtelement Sidelist'></li>
            <li  onClick={(e) => handleClick("")}  className='tgirdelement Sidelist'></li>
            <li  onClick={(e) => handleClick("")}  className='fouurelement Sidelist'></li>
            <li  onClick={(e) => handleClick("")}  className='fiveelement Sidelist'></li>

          </ul>
        </div>
        <div className='col-lg-6'>

        </div>
        <div className='col-lg-4'>

        </div>
      </div>

     </div>
      
    </>
  );
};

export default SteeringCommittee;
