import React, { useState, useEffect } from 'react';


const TechnicalCommittee = () => {
  const [loading, setLoading] = useState(true);


  return (
    <>
      <div className='center mediaTitle'>
        <h2 className='center'>Technical Committee</h2>
      </div>
      <div className='mainContainer'>
      <div className='row'>
        <div className='col-lg-2 sideCon' >
          
        </div>
        <div className='col-lg-6'>

        </div>
        <div className='col-lg-4'>

        </div>
      </div>

     </div>
    </>
  );
};

export default TechnicalCommittee;
