import React, { useState, useEffect } from 'react';
import { Spin, Pagination, Modal } from 'antd';
import { fetchImage } from '../../actions/media';
import { useDispatch, useSelector } from "react-redux";

const ITEMS_PER_PAGE = 10;

const Contact_Info_Organization = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { users = [], loading, error } = useSelector((state) => state.media);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentImages = users.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <div className='center mediaTitle'>
        <h2 className='center'>Contact Information to Organization screening</h2>
      </div>
      
    </>
  );
};

export default Contact_Info_Organization;
