import React, { useState, useEffect } from 'react';
import { Spin, Pagination, Modal } from 'antd';
import { fetchImage } from '../../actions/media';
import { useDispatch, useSelector } from "react-redux";

const ITEMS_PER_PAGE = 10;

const Synopsis = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { users = [], loading, error } = useSelector((state) => state.media);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentImages = users.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <div className='center mediaTitle'>
        <h2 className='center'>Synopsis</h2>
        <p>"The Green Legacy: Ethiopia's Answer to Climate Challenge'' is a compelling documentary that delves into the profound impact of climate change on the diverse landscapes and communities of Ethiopia. Against the backdrop of breathtaking natural beauty and cultural richness, the film explores the urgent need for a sustainable solution to combat the escalating environmental crisis. An initiative called ‘The Green Legacy’ is put forward. The documentary is structured by the tradition of oral storytelling in Ethiopia, inspired by the famous storyteller Aesop and Ethiopian Folklore.</p>
      </div>
      
    </>
  );
};

export default Synopsis;
