import React, { useEffect, useState } from "react";
import "../../css/App.css";
import Logo from "../../asset/images/green_legacy_logo.png";
import Treehand from "../../asset/images/Tree-hand.png"
import "../../css/style.css";
import { BrowserRouter as Router, Route, NavLink, Switch,useNavigate } from 'react-router-dom';
import axios from "axios";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
function NavBar() {
  const [name, setName] = useState("");
  const [isResponsive, setIsResponsive] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const toggleResponsive = () => {
    // console.log('Before toggle:', isResponsive); // Debugging log
    setIsResponsive(!isResponsive);
    // Debugging log
};

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
 

 
  const targetDate = new Date('2024-08-23'); // Target date: 23rd August 2024

  const calculateDaysLeft = () => {
      const today = new Date();
      // Calculate the difference in time
      const timeDiff = targetDate - today;
      // Convert time difference to days
      const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      return daysLeft;
  };

  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());

  useEffect(() => {
      if (daysLeft > 0) {
          // Update the countdown every day
          const interval = setInterval(() => {
              setDaysLeft(calculateDaysLeft());
          }, 1000 * 60 * 60 * 24); // Update every 24 hours

          // Clear the interval when the component unmounts or daysLeft reaches 0
          return () => clearInterval(interval);
      }
  }, [daysLeft]);



 





  return (
    <>
          
          
      <header id="header" className="header-size-sm fixed-top" data-sticky-shrink="false" >
        <div className=" ">
          <div className="header-row">
            <div id="logo" className="logo">
              <div className="Top_logo">
                <a href="/">
                  {" "}
                  <img src={Logo} />
                </a>
                <div className="vertical-line"></div>

                <h3>  Green Legacy Initiative </h3>
                 <h4 className="b-text" >
                <center> "የምትተክል ሀገር ፣ የሚያፀና ትውልድ" </center>
                 "A Nation that plants, a Generation that sustains"
                    </h4><img className="imageHand"src={Treehand}/>              
</div>
            </div>
            {/* {daysLeft > 0 && (
                <h2 >
                    <span>{daysLeft} </span>Day{daysLeft > 1 ? 's' : ''} Left
                   
                </h2>
            )} */}
            <div className="d-lg-flex">
              <ul className="header-extras">
                <div className="Green-legacy-text ">
                  <p> GLI Portal</p>
                </div>


              </ul>
            </div>
          </div>
        </div>
        <div className={`topnav ${isResponsive ? 'responsive' : ''}`} id="myTopnav">
  <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink>
  <NavLink to="/greenLegacyDay" className={({ isActive }) => (isActive ? 'active' : '')}>One Day</NavLink>
  <NavLink to="/regional_data" className={({ isActive }) => (isActive ? 'active' : '')}>Regional Data</NavLink>
  <NavLink to="/annual" className={({ isActive }) => (isActive ? 'active' : '')}>Annual Data</NavLink>
  <NavLink to="/dataset" className={({ isActive }) => (isActive ? 'active' : '')}>Datasets</NavLink>
  <NavLink to="/news" className={({ isActive }) => (isActive ? 'active' : '')}>News</NavLink>

  {/* <NavLink to="/gallery" className={({ isActive }) => (isActive ? 'active' : '')}>Gallery</NavLink> */}
  <div className="dropdown">
    {/* <NavLink to="/Steeringcommitte" className={({ isActive }) => (isActive ? 'active' : '')}>
      Coordination
      <i className="fa fa-caret-down dr-icon"></i>
    </NavLink> */}
    <div className="dropdown-content">
      {/* <NavLink to="/Steeringcommitte"className={({ isActive }) => (isActive ? 'active' : '')}>Steering_committee </NavLink> */}
      {/* <NavLink to="/technicalcommitte"className={({ isActive }) => (isActive ? 'active' : '')}>Technical_committee</NavLink>  */}
    </div>
  </div>
  <a href="#" className="icon" onClick={toggleResponsive}>
    <i className="fa fa-bars"></i>
  </a>
</div>

  
      </header>
      <div className=""style={{marginBottom:"120px"}}></div>

     
      </>
  );

}

export default NavBar;
