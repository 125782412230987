import React, { useState } from "react";
import VideoGallery from "../components/media/video";
import ImageGallery from "../components/media/image";
import Information from "../components/news/information";
import News from "../components/news/news";
import Synopsis from "../components/news/synopsis";
import Contact_Info_Organization from "../components/news/Contact information to organize screenings";
import Information_Screening from "../components/news/info about screenings";
import Trailers from "../components/news/trailer";
import Stills1 from "../components/news/stills";
import { DoubleRightOutlined  } from "@ant-design/icons";
import Information1 from "../components/news/information";
import News1 from "../components/news/news";


const Documents = () => {
  const [currentComponent, setCurrentComponent] = useState(<News />);

  const handleClick = (item) => {
    let componentToRender;

    switch (item) {
      case "News":
        componentToRender = <News1/>;
        break;  
      case "Synopsis":
        componentToRender = <Synopsis />;
        break;        
        case "Stills":
          componentToRender = <ImageGallery/>;
          break;
        case "Contact_Info":
            componentToRender = <Contact_Info_Organization/>;
          break;
        case "Info_Screening":
              componentToRender = <Information_Screening/>;
          break;
        case "trailer":
                componentToRender = < VideoGallery/>;
          break;
                 
     
   
      default:
        componentToRender = <ImageGallery />;
        break;
    }

    setCurrentComponent(componentToRender); // Update state with the new component
  };
  return (
    <>
    <div className="row">
    <div className="col-lg-3">

    <div className="">
    <div className="GLI_sidebar" >
          
                     <ul>
                     <h4 > Green Legacy Initiative (GLI) </h4>  
                      
                        <h3 onClick={(e) => handleClick("News")}><a> News</a> </h3>

                        <br/>

                        <li><h5>Documentary</h5><hr/>
                        <ul>
                        <h6 onClick={(e) => handleClick("Synopsis")}><h6><a><DoubleRightOutlined /> Synopsis</a></h6> </h6>
                        <h6 onClick={(e) => handleClick("Stills")}><a><DoubleRightOutlined /> Stills</a></h6>
                        <h6 onClick={(e) => handleClick("Contact_Info")}><a><DoubleRightOutlined /> Contact info</a></h6>
                        <h6 onClick={(e) => handleClick("Info_Screening")}><a><DoubleRightOutlined /> Info about screenings</a> </h6>
                        <h6 onClick={(e) => handleClick("trailer")}><a><DoubleRightOutlined /> trailer</a></h6>

                        </ul></li>
                                           
                        
                    
                      </ul>
                     
            </div>

 
            </div>
            </div>
            <div className='col-lg-9'>
            {currentComponent}

            </div>

    </div>
     


    </>
  );
};

export default Documents;
