import "./css/custom.css";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import NavBar from "./components/allPage/base";
import Footer from "./components/allPage/Footer";
import Login from "./pages/Login";
import Regional_data from "./pages/Regional_data";
import Site_data from "./components/site/Site_data";
import Contact from "./pages/Contact";
import Annual from "./pages/Annual";
import Dataset from "./pages/Dataset";
import Upload_form from "./store/upload_form";
import No_found from "./components/error/no_found";
import Gallery from "./pages/Gallery";
import UploadSiteData from "./store/siteAdmin/upload_site_data";
import UploadRegionalData from "./store/regionalAdmin/upload_regional_data";
import UploadAnuualData from "./store/annualAdmin/upload_years_data";
import GreenLegacyDay from "./pages/GreenLegacyDay";
import { setLoggedIn, setName, setUserType } from "./actions/users";
import PrivateRoute from "./store/Auth/PrivateRoute";
import AdminPrivateRoute from "./store/Auth/AdminPrivateRoute ";
import { UserSwitchOutlined } from "@ant-design/icons";
import UserTypeRoute from "./store/Auth/UserTypeRoute";
import HomeIndex from "./pages";
import NumberForm from "./store/oneday/oneDay";
import { fetchTokenAndUpdateUser } from "./actions/token";
import Participation from "./store/oneday/participation";
import OnedayForm from "./store/oneday/oneDayForm";
import TechnicalCommittee from "./pages/TechnicalCommittee";
import SteeringCommittee from "./pages/SteeringCommitte";
import ParticipationAdmin from "./store/oneday/participantAdmin";
import RegionPrivateRoute from "./store/Auth/RegionPrivateRoute ";
import AnnualPrivateRoute from "./store/Auth/AnnualPrivateRoute";
import OnedayPrivateRoute from "./store/Auth/OnedayPrivateRoute ";
import ParticipantPrivateRoute from "./store/Auth/ParticipantPrivateRoute";
import Unauthorized from "./pages/unauthorized";
import Documents from "./pages/Document";
function App() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTokenAndUpdateUser());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
             {window.location.pathname !== "/login" &&
              window.location.pathname !== "/upload" &&
              window.location.pathname !== "/uploadSiteData" &&
              window.location.pathname !== "/uploadRegionalData" &&
              window.location.pathname !== "/uploadNationalData" && (
            <>
              <Route path="/"element={ <><NavBar /><HomeIndex /><Footer /></>}/>
              <Route path="/GreenLegacyDay"element={<><NavBar /><GreenLegacyDay /><Footer /></>} />
              <Route path="/Contact"element={<><NavBar /><Contact /><Footer /></> }/>
              <Route path="/regional_data"element={ <> <NavBar /><Regional_data /><Footer /></>}/>
              <Route  path="/site_data"element={<><NavBar /><Site_data /><Footer /></>}/>
              <Route path="/annual"element={<> <NavBar /><Annual /><Footer  /></>}/>
              <Route path="/gallery"element={<><NavBar /><Gallery /><Footer /></>}/>
              <Route path="/news"element={<><NavBar /><Documents/><Footer /></>}/>

              <Route path="/dataset"element={<><NavBar /><Dataset /><Footer /></>}/>
              <Route path="/index"element={<><NavBar /><HomeIndex /><Footer /></>}/>
     
              <Route path="/steeringcommitte"element={<><NavBar /><SteeringCommittee /><Footer /></>}/>
              <Route path="/technicalcommitte"element={<><NavBar /><TechnicalCommittee /><Footer /></>}/>

            </>
          )}

            {/* <Route path="/upload" element={<Upload_form />} />
            <Route path="/uploadSiteData" element={<UploadSiteData />} />
            <Route path="/uploadRegionalData" element={<UploadRegionalData />} />
            <Route path="/uploadNationalData" element={<UploadAnuualData />} />  */}
            
        
        {/* protected routes */}
        <Route path="/upload" element={<AdminPrivateRoute><Upload_form /></AdminPrivateRoute>} />
{/* <Route path="/uploadSiteData" element={<AdminPrivateRoute><UploadSiteData /></AdminPrivateRoute>} /> */}
<Route path="/uploadRegionalData" element={<RegionPrivateRoute><UploadRegionalData /></RegionPrivateRoute>} />
<Route path="/UploadNationalData" element={<AnnualPrivateRoute><UploadAnuualData /></AnnualPrivateRoute>} />
<Route path="/oneday"element={<OnedayPrivateRoute><OnedayForm /></OnedayPrivateRoute>}/>
<Route path="/participant"element={<ParticipantPrivateRoute><ParticipationAdmin /></ParticipantPrivateRoute>}/>


              


            <Route path="/login" element={<Login />} />
            <Route path="/GreenLegacyDay" element={<GreenLegacyDay />} />
            <Route path="*" element={<No_found />} />
            <Route path="unauthorized" element={<Unauthorized />} />




          </Routes>
    </BrowserRouter>
  );
}

export default App;
