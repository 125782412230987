import React, { useState } from "react";
import seed from "../../asset/images/green_legacy_logo.png";

const News1 = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="news-body">
      <div className="container">
        <div className="article">
          <img src={seed} alt="Green Legacy Logo" />
          <div className="article-content news_body">
            <h3>Screening at international film festivals in the coming months</h3>
            <p>
              For the past six years, Ethiopia has dedicated itself to an ambitious annual planting program through the Green Legacy Initiative, launched by Prime Minister Abiy Ahmed in 2019. The recently released Forest Cover report reveals remarkable progress: Ethiopia's forest cover has expanded from 17.2% in 2019 to 23.6% by 2023.
            </p>
            {isReadMore && (
              <>
                <p>
                  Marking this milestone, the Green Legacy film—an international documentary that premiered in June 2024—captures the profound impact of this national effort. Through compelling personal stories, the film highlights how the Green Legacy Initiative has transformed Ethiopia’s landscape and enriched the lives of its people.
                </p>
                <p>This impactful film has been selected for screening at international film festivals in the coming months:</p>
                <p>1) Bridge for Peace Festival in France for Best Documentary — November 9, 2024</p>
                <p>2) Africa USA International Film Festival — November 17, 2024</p>
                <p>3) Logo Lumina Flicks International Short Film Festival in Albania — December 26, 2024</p>
                <p>4) Cinemaking International Film Festival in Bangladesh — December 26, 2024</p>
              </>
            )}
            <div className="article-date">
              <span>&#128197;</span>
              <span>November 2024</span>
            </div>
            <button onClick={toggleReadMore} className="read-more">
              {isReadMore ? "Show Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News1;
