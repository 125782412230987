import React, { useState, useEffect } from 'react';
import { Spin, Pagination, Modal } from 'antd';
import { fetchImage } from '../../actions/media';
import { useDispatch, useSelector } from "react-redux";

const ITEMS_PER_PAGE = 10;

const ImageGallery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { users = [], loading, error } = useSelector((state) => state.media);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentImages = users.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <div className='center mediaTitle'>
      </div>
      <div className='center spin'>
        {loading ? (
          <Spin tip="Loading" size="large" />
        ) : (
          <div>
            <div className='' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {currentImages.map((image, index) => (
                <div key={index} style={{ margin: 10 }}>
                  <img
                    src={image.path}
                    alt={image.filename}
                    style={{ width: 200, height: 200, objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => handleImageClick(image)}
                  />
                  <p style={{ width: 200, objectFit: 'cover', cursor: 'pointer' }}>
                    {image.filename}
                  </p>
                </div>
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={ITEMS_PER_PAGE}
              total={users.length}
              onChange={handlePageChange}
              style={{ marginTop: 20, textAlign: 'center' }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleModalClose}
        centered
        bodyStyle={{ padding: 10 }}
        width={1000}
      >
        {selectedImage && (
          <img
            src={selectedImage.path}
            alt={selectedImage.filename}
            style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
          />
        )}
      </Modal>
    </>
  );
};

export default ImageGallery;
